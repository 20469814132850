export const SETCalculation = (state, calculation) => {
  state.calculation = calculation;
};

export const SETshowSideBarElements = (state, showSideBarElements) => {
  state.showSideBarElements = showSideBarElements;
};

export const SETProfitData = (state, profitData) => {
  state.profitData = profitData;
};

export const SETShowLoadingScreen = (state, showLoadingScreen) => {
  state.showLoadingScreen = showLoadingScreen;
};

export const SETIsPayment = (state, isPayment) => {
  state.isPayment = isPayment;
};

export const SETToken = (state, token) => {
  state.token = token;
};

export const SETSavedCalculationList = (state, calculationList) => {
  state.calculationList = calculationList;
};

export const SETNameOfUser = (state, nameOfUser) => {
  state.nameOfUser = nameOfUser;
};

export const SETbusinessName = (state, businessName) => {
  state.businessName = businessName;
};
