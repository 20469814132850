import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueApexCharts from "vue3-apexcharts";
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBgDGrR13lmMG_yCIbl16VIvruZcOcIkkI",
  authDomain: "aogreen-d35f4.firebaseapp.com",
  projectId: "aogreen-d35f4",
  storageBucket: "aogreen-d35f4.appspot.com",
  messagingSenderId: "207774641411",
  appId: "1:207774641411:web:104b2c81430dcc6dd6cd36",
  measurementId: "G-CBNLMYXYQ5",
};

firebase.initializeApp(firebaseConfig);
createApp(App).use(store).use(router).use(VueApexCharts).mount("#app");

async function getCustomClaimRole() {
  console.log("getCustomClaimRole");
  await firebase.auth().currentUser.getIdToken(true);
  const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
  console.log(decodedToken);
  return decodedToken.claims.stripeRole;
}

const db = firebase.firestore();

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    store.dispatch("setShowLoadingScreen", true);
    db.collection("customers")
      .doc(user.uid)
      .collection("subscriptions")
      .where("status", "in", ["trialing", "active"])
      .get()
      .then(async (snapshot) => {
        if (snapshot.empty) {
          store.dispatch("setShowLoadingScreen", false);
          router.push("/Auth");
          store.dispatch("setIsPayment", true);
        }
        getCustomClaimRole().then((role) => {
          console.log(role);
          if (role == "premium") {
            store.dispatch("getSavedCalculation");
            store.dispatch("setShowLoadingScreen", false);
            store.dispatch("setNameOfUser", user.displayName);
            store.dispatch("setBusinessName");
            router.push("/tabs/Calculation");
          }
        });
      });
  } else {
    router.push("/Auth");
  }
});
