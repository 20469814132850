import firebase from "firebase";
const ServerURL = process.env.VUE_APP_ENV_SERVER || "";
import axios from "axios";
export const setCalculation = ({ commit }, payload) => {
  commit("SETCalculation", payload);
};

export const setToken = ({ commit }, payload) => {
  commit("SETToken", payload);
};

export const showSideBarElements = ({ commit }) => {
  commit("SETshowSideBarElements", true);
};

export const setProfitData = ({ commit }, payload) => {
  commit("SETProfitData", payload);
};

export const setShowLoadingScreen = ({ commit }, payload) => {
  commit("SETShowLoadingScreen", payload);
};

export const setIsPayment = ({ commit }, payload) => {
  commit("SETIsPayment", payload);
};

export const getSavedCalculation = ({ commit }) => {
  firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(function (idToken) {
      axios
        .get(ServerURL + "/getSavedCalculation", {
          params: {
            idToken: idToken,
          },
        })
        .then((response) => response.data)
        .then((returnCalculation) => {
          commit("SETSavedCalculationList", returnCalculation.calculations);
        });
    });
};

export const setNameOfUser = ({ commit }, payload) => {
  commit("SETNameOfUser", payload);
};

export const setBusinessName = ({ commit }) => {
  firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(function (idToken) {
      axios
        .get(ServerURL + "/getBusinessName", {
          params: {
            idToken: idToken,
          },
        })
        .then((response) => response.data)
        .then((businessName) => {
          commit("SETbusinessName", businessName);
        });
    });
};
