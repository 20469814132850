import { createStore } from "vuex";
import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";
const state = {
  calculation: {},
  showSideBarElements: false,
  profitData: [],
  showLoadingScreen: false,
  isPayment: false,
  token: "",
  calculationList: [],
  nameOfUser: "",
  businessName: "",
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
});
