<template>
  <div
    class="isHidden"
    :class="{
      smallWindow: this.window.width < 750 || this.window.height < 650,
    }"
  >
    <div class="popUp">
      <h2>Failure</h2>
      <p>
        Your Screensize is too small. This tool only works on desktop-sized
        devices.
      </p>
    </div>
  </div>
  <router-view />
  <Loading v-if="this.$store.getters.showLoadingScreen"></Loading>
</template>

<script>
import Loading from "@/components/Loading.vue";

export default {
  components: {
    Loading,
  },

  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";
.isHidden {
  display: none;
  background: red;
}
.smallWindow {
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  pointer-events: none;
  opacity: 0.6;

  .popUp {
    width: 90vw;
    border: 4px solid grey;
    border-radius: 8px;
    display: flex;
    padding: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      color: white;
      padding: 10px;
      padding-bottom: 0;
    }

    p {
      color: white;
      padding: 10px;
      padding-top: 0;
    }
  }
}
</style>
