import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/Auth",
  },
  {
    path: "/Auth",
    component: () => import("../views/Auth.vue"),
  },
  {
    path: "/CreateReport",
    component: () => import("@/views/CreateReport.vue"),
  },
  {
    path: "/tabs/",
    component: () => import("../views/NavBar.vue"),
    children: [
      {
        path: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "AirDensityCalculation",
        component: () => import("../views/AirDensityCalculation.vue"),
        meta: {
          title: "Air Density Calculation",
        },
      },
      {
        path: "Calculation",
        component: () => import("../views/Calculation.vue"),
      },
      {
        path: "OutputPrediction",
        component: () => import("../views/OutputPrediction.vue"),
        meta: {
          title: "Output Prediction",
        },
      },
      {
        path: "ProfitabilityAnalysis",
        component: () => import("../views/ProfitabilityAnalysis.vue"),
        meta: {
          title: "Profitability Analysis",
        },
      },
      {
        path: "WeibullDistribution",
        component: () => import("../views/WeibullDistribution.vue"),
        meta: {
          title: "Weibull Distribution",
        },
      },
      {
        path: "WindspeedCalculation",
        component: () => import("../views/WindspeedCalculation.vue"),
        meta: {
          title: "Windspeed Calculation",
        },
      },
      {
        path: "Profile",
        component: () => import("../views/Profile.vue"),
        meta: {
          title: "Profile",
        },
      },
      {
        path: "FAQ",
        component: () => import("../views/faq.vue"),
        meta: {
          title: "FAQ",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title
      ? toRoute.meta.title + " - Windanalytics by AOGreen"
      : "Windanalytics by AOGreen";
  next();
});

export default router;
