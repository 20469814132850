export const calculation = (state) => {
  return state.calculation;
};

export const showSideBarElements = (state) => {
  return state.showSideBarElements;
};

export const profitData = (state) => {
  return state.profitData;
};

export const showLoadingScreen = (state) => {
  return state.showLoadingScreen;
};

export const isPayment = (state) => {
  return state.isPayment;
};

export const token = (state) => {
  return state.token;
};

export const calculationList = (state) => {
  return state.calculationList;
};

export const nameOfUser = (state) => {
  if (!state.nameOfUser || state.nameOfUser == "") {
    return "Settings";
  } else {
    return state.nameOfUser;
  }
};

export const businessName = (state) => {
  if (!state.businessName || state.businessName == "") {
    return "";
  } else {
    return state.businessName;
  }
};
